import { gql, useSubscription } from '@apollo/client';
import { Check, SubscriptionAssignedCheckArgs } from 'apollo/generated/types';

const ASSIGN_CHECK_SUBSCRIPTION = gql`
  subscription assignedCheck( $id: String!) {
    assignedCheck(id: $id) {
      id
      card {
        id
      }
    }
  }
`;

const useAssignedCheckSubscription = (id: string | undefined) => useSubscription<
  { assignedCheck: Check }, SubscriptionAssignedCheckArgs
>(ASSIGN_CHECK_SUBSCRIPTION, { variables: { id: id || '' }, skip: !id, shouldResubscribe: !!id });

export default useAssignedCheckSubscription;
