import { FC, useState } from 'react';
import { toDataURL } from 'qrcode';

const QRCode: FC<{payload: string;}> = (props: {payload: string;}) => {
  const [imageData, setImageData] = useState<string>();
  const { payload } = props;
  toDataURL(payload, { errorCorrectionLevel: 'M' }).then((res) => setImageData(res));
  return (
    <img
      src={imageData}
      alt="QRCode"
      style={{ width: '70vw', maxWidth: '400px', marginBottom: '2vh' }}
    />
  );
};

export default QRCode;
