import { Client } from 'apollo/types';
import { TableClient } from 'components/clients/ClientsTable/types';
import getActiveChecksNumber from 'utils/card/getActiveChecksNumber';
import daysFromNow from 'utils/dates/daysFromNow';
import diffInDays from 'utils/dates/diffInDays';

export default (client: Client): TableClient | null => {
  if (client.cards) {
    const currentCardRepetitions = client.cards && client.cards[0].cardTemplate.repetitions;
    const totalChecks = client.cards[0].checks.length;
    const subscribedAt = new Date(client.cards[0].createdAt);
    const sortedChecks = Array.from(client.cards[0].checks).sort(
      (checkA, checkB) => new Date(checkA.updatedAt).valueOf()
      - new Date(checkB.updatedAt).valueOf(),
    );
    const latestCheck = sortedChecks.slice(-1)[0];
    const lastVisitDistance = daysFromNow(latestCheck?.updatedAt || subscribedAt);
    const usedCoupons = (client.cards && client.cards[0].coupons
      ?.filter((coupon) => coupon.usedAt)) || [];
    const activeCoupons = (client.cards && client.cards[0].coupons
      ?.filter((coupon) => !coupon.usedAt)) || [];
    const latestChecks = sortedChecks
      .filter((check) => daysFromNow(check.updatedAt) <= 60);
    let recentVisitsAveragePeriod = null;
    if (latestChecks.length > 1) {
      const totalDiff = diffInDays(
        latestChecks.slice(-1)[0].updatedAt,
        latestChecks[0].updatedAt,
      );
      recentVisitsAveragePeriod = Math.round((totalDiff / latestChecks.length) * 10) / 10;
    }

    return {
      fullName: `${client.name} ${client.familyName}`,
      email: client.email,
      subscribedAt,
      totalChecks,
      currentCardChecks: currentCardRepetitions
      && getActiveChecksNumber(totalChecks, currentCardRepetitions),
      currentCardRepetitions,
      couponsUsed: usedCoupons.length,
      activeCoupons: activeCoupons.length,
      recentVisitsAveragePeriod,
      lastVisitDistance,
    };
  }
  return null;
};
