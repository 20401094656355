import { gql, useMutation } from '@apollo/client';
import { Coupon, MarkCouponAsUsedInput } from 'apollo/types';

const MARK_COUPON_AS_USED = gql`
  mutation markCouponAsUsed(
    $id: String!
  ) {
    markCouponAsUsed(
      input: {
        id: $id
      }
    ) {
      id
      prize
      createdAt
      usedAt
      card {
        id
        cardTemplate {
          icon
          company {
            name
          }
        }
        client {
          name
          familyName
        }
      }
    }
  }
`;

const useMarkCouponAsUsedMutation = () => useMutation<
  { markCouponAsUsed: Coupon }, MarkCouponAsUsedInput
>(MARK_COUPON_AS_USED);

export default useMarkCouponAsUsedMutation;
