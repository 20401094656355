import { gql, useQuery } from '@apollo/client';
import { Company } from 'apollo/generated/types';

// TODO: Refactor GET MY COMPANY to receive parameters
const GET_FULL_COMPANY = gql`
  query getMyCompany {
    getMyCompany {
      id
      name
      menuUrl
      logoUrl
      cardTemplates {
        prize
        repetitions
        icon
        QRCode {
          id
        }
      }
    }
  }
`;

const useGetFullCompanyQuery = () => useQuery<
{ getMyCompany: Company }, void
>(GET_FULL_COMPANY, { });
export default useGetFullCompanyQuery;
