import { gql, useQuery } from '@apollo/client';
import { Client } from 'apollo/generated/types';

const LIST_MY_CLIENTS = gql`
  query listMyClients {
    listMyClients {
      dni
      email
      name
      familyName
      cards {
        id
        createdAt
        checks {
          updatedAt
        }
        coupons {
          id
          prize
          createdAt
          usedAt
        }
        cardTemplate {
          repetitions
        }
      }
    }
  }
`;

const useListMyClientsQuery = () => useQuery<
{ listMyClients: Client[] }, void
>(LIST_MY_CLIENTS, { });
export default useListMyClientsQuery;
