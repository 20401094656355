import React, {
  FC, useRef, useState,
} from 'react';
import {
  Button, Col, Image, Row,
} from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as texts from 'assets/texts/graphicMaterial';
import Checks from 'components/card/Checks';
import useGetFullCompanyQuery from 'apollo/resolvers/company/getFull';
import LoadingModal from 'components/generalUI/LoadingModal';
import QRCode from 'components/generalUI/QRCodeLogo';
import { companyName } from 'assets/texts/error';
import styles from './GraphicMaterial.module.scss';

enum PdfDisposition {
  l = 'l',
  p = 'p',
}

enum GraphMaterialOptions {
  pdf = 'pdf',
  ig = 'ig',
  qr = 'qr',
  all = 'all',
}

const GraphicMaterial: FC = () => {
  const { data, loading } = useGetFullCompanyQuery();
  const [isIgPostDownload, setIsIgPostDownload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const company = data?.getMyCompany;
  const cardTemplate = company?.cardTemplates.at(-1);
  const prize = cardTemplate?.prize || '';
  const repetitions = cardTemplate?.repetitions || 6;
  const icon = cardTemplate?.icon || 'SMILE';
  const qrCodeId = cardTemplate?.QRCode?.id;
  const companyLogoUrl = company?.logoUrl;
  const activeChecks = [1];

  const pdfPrintRef = useRef<HTMLDivElement>(null);
  const QRCodePrintRef = useRef<HTMLDivElement>(null);
  const IGPrintRef = useRef<HTMLDivElement>(null);

  const exportPdf = async () => {
    const element = pdfPrintRef.current as HTMLElement;
    if (element) {
      const canvas = await html2canvas(element, {
        logging: true,
        allowTaint: false,
        useCORS: true,
      });
      const imgData = canvas.toDataURL('img/png');
      const pdfDimensions = {
        width: canvas.width,
        height: canvas.height,
        disposition: PdfDisposition.l,
      };
      if (canvas.width < canvas.height) {
        pdfDimensions.width = canvas.height;
        pdfDimensions.height = canvas.width;
        pdfDimensions.disposition = PdfDisposition.p;
      }
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF(
        pdfDimensions.disposition,
        'mm',
        [pdfDimensions.width, pdfDimensions.height],
      );
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'PNG', 0, 0, width, height);
      pdf.save(texts.pdfFileName(companyName));
      setIsDownloading(false);
    }
  };

  const exportQrCode = async () => {
    const element = QRCodePrintRef.current as HTMLElement;
    if (element) {
      const canvas = await html2canvas(element, {
        logging: true,
        allowTaint: false,
        useCORS: true,
      });

      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.setAttribute('download', texts.qrFileName(companyName));
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    }
  };

  const handleGenerateIgPost = async ():Promise<void> => {
    const element = IGPrintRef.current as HTMLElement;
    if (element) {
      const canvas = await html2canvas(element, {
        logging: true,
        allowTaint: false,
        useCORS: true,
        windowWidth: 700,
      });
      const imgData = canvas.toDataURL('img/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.setAttribute('download', texts.igPostFileName(companyName)); // or any other extension
      document.body.appendChild(link);
      link.click();
      setIsIgPostDownload(false);
      setIsDownloading(false);
    }
  };

  const exportElement = async (graphMaterial: GraphMaterialOptions) => {
    setIsDownloading(true);
    switch (graphMaterial) {
      case 'ig':
        setIsIgPostDownload(true);
        setTimeout(() => {
          handleGenerateIgPost();
        }, 800);
        break;
      case 'pdf':
        setTimeout(() => {
          exportPdf();
        }, 800);
        break;
      case 'qr':
        setTimeout(() => {
          exportQrCode();
        }, 800);
        break;
      case 'all':
        setTimeout(() => {
          exportQrCode();
          exportPdf();
          setIsIgPostDownload(true);
        }, 800);
        setTimeout(async () => {
          await handleGenerateIgPost();
        }, 800);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Col>
        <h1>{texts.title}</h1>
        <h3>{texts.subTitle}</h3>
      </Col>
      <Row style={{ justifyContent: 'center' }}>
        <Button
          type="primary"
          className={styles.DownloadButton}
          onClick={() => exportElement(GraphMaterialOptions.all)}
          loading={isDownloading}
        >
          {texts.downloadAllButton}
        </Button>

      </Row>
      <Row style={{ justifyContent: 'center', marginBottom: '3vh' }}>
        <Button
          className={styles.DownloadButton}
          onClick={() => exportElement(GraphMaterialOptions.pdf)}
          loading={isDownloading}
        >
          {texts.downloadPDFButton}
        </Button>
        <Button
          className={styles.DownloadButton}
          onClick={() => exportElement(GraphMaterialOptions.qr)}
          loading={isDownloading}
        >
          {texts.downloadQRButton}
        </Button>
        <Button
          className={styles.DownloadButton}
          onClick={() => exportElement(GraphMaterialOptions.ig)}
          loading={isDownloading}
        >
          {texts.downloadIGButton}
        </Button>
      </Row>
      <Col ref={IGPrintRef}>
        <Col
          className={isDownloading ? styles.GraphMaterialDownloading : styles.GraphMaterialNormal}
          ref={pdfPrintRef}
        >
          {
        !loading ? (
          <>
            <Row style={{ justifyContent: 'center', margin: '30px 0 30px 0' }}>
              {
              companyLogoUrl && (
                <Image
                  width={80}
                  src={`${companyLogoUrl}?_random`}
                  crossOrigin="anonymous"
                />
              )
            }
            </Row>
            <Row className={styles.RewardContainer}>
              <div className={styles.outer}>
                <div className={styles.inner}>
                  <p className={styles.PrimaryLargeText} style={{ marginBottom: 0 }}>
                    {texts.rewardDescription}
                  </p>
                  <p className={styles.RewardText} style={{ marginBottom: 0 }}>
                    {texts.prizeDescription(prize)}

                  </p>
                </div>
              </div>
            </Row>
            <Row style={{ justifyContent: 'center', marginTop: '30px' }}>
              <p
                className={styles.PrimaryLargeText}
                style={{ fontSize: 'large', margin: '10px 0 0 0' }}
              >
                {texts.checksAmountLabel(repetitions)}

              </p>
            </Row>
            <Row style={{ justifyContent: 'center', margin: 0 }}>
              <Checks
                iconSize="30px"
                repetitions={repetitions}
                checks={activeChecks || []}
                icon={icon}
              />
            </Row>
            <Row style={{ justifyContent: 'center', margin: 0 }}>
              <p className={styles.PrimaryLargeText} style={{ fontSize: 'large' }}>
                {texts.checksAmountSubtitle}
              </p>
            </Row>
            <Row style={{ justifyContent: 'center', margin: 0 }}>
              {
                isIgPostDownload ? (
                  <p className={styles.PrimaryLargeText} style={{ marginBottom: '100px' }}>
                    {texts.IgPostAdvice}
                  </p>
                ) : (
                  <Col style={{ width: '100%' }}>
                    <p className={styles.PrimaryLargeText}>
                      {texts.qrScanAdvice}
                    </p>
                    <QRCode
                      ref={QRCodePrintRef}
                      payload={`https://volbi.app/card/${qrCodeId}`}
                    />
                  </Col>
                )
              }
            </Row>
          </>
        ) : (
          <LoadingModal visible={loading} />
        )
      }
        </Col>
      </Col>
    </>
  );
};

export default GraphicMaterial;
