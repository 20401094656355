import { Row, Col } from 'antd';
import QRScanner from 'components/QRScanner';
import React, { FC } from 'react';
import * as texts from '../../assets/texts/scanCoupon';

const ScanCoupon: FC = () => (
  <Row style={{ width: '90%', margin: 'auto' }}>
    <Col span={24} md={12}>
      <h1>{texts.title}</h1>
      <h3>{texts.subTitle}</h3>
      <h4 style={{ textAlign: 'center' }}>{texts.body}</h4>
      <QRScanner header={null} />
    </Col>
  </Row>
);
export default ScanCoupon;
