import { gql, useQuery } from '@apollo/client';
import { Company } from 'apollo/generated/types';

const GET_MY_COMPANY = gql`
  query getMyCompany {
    getMyCompany {
      name
      cardTemplates {
        QRCode {
          id
        }
      }
    }
  }
`;

const useGetMyCompanyQuery = () => useQuery<
{ getMyCompany: Company }, void
>(GET_MY_COMPANY, { });
export default useGetMyCompanyQuery;
