import React, {
  forwardRef, useState,
} from 'react';
import * as qrcode from 'qrcode';
import VolbiLogo from 'assets/images/logoBox.png';

const QRCode = forwardRef<HTMLDivElement, {payload: string}>(
  (props, ref) => {
    const [imageData, setImageData] = useState<string>();
    const { payload } = props;
    qrcode.toDataURL(payload, { errorCorrectionLevel: 'L' }).then((res) => setImageData(res));
    return (
      <div style={{ position: 'relative', width: '60%', margin: 'auto' }} ref={ref}>
        <img
          src={imageData}
          alt="QRCode"
          style={{
            width: '100%',
            marginBottom: '2vh',
            position: 'relative',
            top: 0,
            left: 0,
          }}
        />
        <img
          src={VolbiLogo}
          alt="Volbi Logo"
          style={{
            backgroundColor: '#123123',
            width: '15%',
            marginBottom: '2vh',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto',
          }}
        />
      </div>
    );
  },
);

export default QRCode;
