export const title = 'Material Gráfico';
export const subTitle = 'A continuación podrás descargar tu material gráfico para promocionar'
+ ' tus servicios en redes sociales y sucursales';
export const downloadAllButton = 'Descargar todo';
export const downloadPDFButton = 'Descargar PDF';
export const downloadQRButton = 'Descargar QR';
export const downloadIGButton = 'Descargar Post Instagram';
export const pdfFileName = (companyName: string) => {
  const now = new Date();
  const dateFormat = `${now.getUTCDate()}_${now.getUTCMonth() + 1}_${now.getUTCFullYear()}`;
  const fileName = `pdf_afiche_${companyName}_${dateFormat}.pdf`;
  return fileName;
};
export const qrFileName = (companyName: string) => {
  const now = new Date();
  const dateFormat = `${now.getUTCDate()}_${now.getUTCMonth() + 1}_${now.getUTCFullYear()}`;
  const fileName = `QR_${companyName}_${dateFormat}.png`;
  return fileName;
};
export const igPostFileName = (companyName: string) => {
  const now = new Date();
  const dateFormat = `${now.getUTCDate()}_${now.getUTCMonth() + 1}_${now.getUTCFullYear()}`;
  const fileName = `ig_post_${companyName}_${dateFormat}.png`;
  return fileName;
};
export const rewardDescription = 'El simple camino a tu';
export const checksAmountLabel = (repetitions: number) => `Recíbelo al juntar 
${repetitions} timbres`;
export const prizeDescription = (prize: string) => {
  const firstWordArr = prize.split(' ');
  let formatedPrize = prize;
  if (firstWordArr.length) {
    if (['UN', 'UNA', '1'].includes(firstWordArr[0].toUpperCase())) {
      formatedPrize = firstWordArr.slice(1).join(' ');
    }
  }
  return formatedPrize;
};
export const checksAmountSubtitle = 'Suma uno en cada visita';
export const qrScanAdvice = '¡Empieza a sumar timbres escaneando el QR!';
export const IgPostAdvice = '¡Empieza a juntar timbres en el enlace!';
