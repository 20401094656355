const daysFromNow = (date: Date | string): number => {
  /* Gets (absolute value) difference in calendar days (not cronological as 24h). Uses local time.
  There will always be 1 dayFromNow for yesterday at 23:59:59, and 1 for tomorrow 00:00:00 */
  const dateObject = new Date(date);
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  const diff = today.valueOf() - dateObject.valueOf();
  const diffInDays = Math.ceil(diff / 1000 / 60 / 60 / 24);
  return Math.abs(diffInDays);
};

export default daysFromNow;
