import { Table } from 'antd';
import { FC } from 'react';
import columns from './columns';
import { TableClient } from './types';

interface Props {
  parsedClientsData: TableClient[];
}

const ClientsTable: FC<Props> = (props) => {
  const { parsedClientsData } = props;
  return (<Table dataSource={parsedClientsData} columns={columns} />);
};

export default ClientsTable;
