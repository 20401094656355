import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import useErrorHandler from 'hooks/useErrorHandler';
import logIn from 'auth/methods/logIn';
import LoadingModal from 'components/generalUI/LoadingModal';
import * as texts from 'assets/texts/logIn';
import { useHistory } from 'react-router-dom';
import { refreshToken } from 'auth/storage';
import styles from './Login.module.scss';

const LogIn: FC = () => {
  const history = useHistory<{ from: string }>();
  const [loading, setLoading] = useState(false);
  const [refreshAuthLoading, setRefreshAuthLoading] = useState(true);
  const { showError } = useErrorHandler(null);
  const onSuccess = () => {
    setLoading(false);
    window.location.replace(history.location.state?.from || '');
  };
  const onError = (error: Error) => {
    setLoading(false);
    showError(error);
  };
  const onLoading = () => {
    setLoading(true);
  };
  const onFinish = (values: {
    email: string;
    password: string;
    name: string;
    familyName: string;
  }) => {
    const { email, password } = values;
    logIn(email, password, onSuccess, onLoading, onError);
  };

  const { from } = history.location.state;
  const onRefreshAuthSuccess = () => { history.replace(from || ''); };
  const onRefreshAuthError = () => { setRefreshAuthLoading(false); };
  useEffect(() => {
    refreshToken(onRefreshAuthSuccess, onRefreshAuthError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.MainContainer}>
      <LoadingModal visible={loading || refreshAuthLoading} />
      {!refreshAuthLoading && (
        <>
          <h1>{texts.title}</h1>
          <Form
            name="basic"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className={styles.Form}
          >
            <Form.Item
              label={texts.emailLabel}
              name="email"
              rules={[{ required: true, message: texts.emailWarning }]}
              normalize={(value: string) => value.toLowerCase()}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={texts.passwordLabel}
              name="password"
              rules={[
                {
                  required: true,
                  message: texts.passwordWarning,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item label=" " colon={false}>
              <Button type="default" htmlType="submit" className={styles.SubmitButton}>
                <h4>{texts.logInButton}</h4>
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};

export default LogIn;
