import LoadingModal from 'components/generalUI/LoadingModal';
import { FC } from 'react';
import { Row, Col, Button } from 'antd';
import { useParams } from 'react-router-dom';
import useGetCouponQuery from 'apollo/resolvers/coupon/get';
import CouponTicket from 'components/coupon/CouponTicket';
import useMarkCouponAsUsedMutation from 'apollo/resolvers/coupon/markAsUsed';
import useSuccess from 'hooks/useSuccess';
import { formatDate, formatTime } from 'assets/texts/utils';

interface QueryParams {
  id: string;
}

const Coupon: FC = () => {
  const { id } = useParams<QueryParams>();
  const { data: getData, loading: getLoading } = useGetCouponQuery(id);
  const [markCouponAsUsed,
    { data: updateData, loading: updateLoading }] = useMarkCouponAsUsedMutation();
  const loading = updateLoading || getLoading;
  const data = updateData?.markCouponAsUsed || getData?.getCoupon;
  useSuccess(!!updateData, 'Cupón marcado como usado con éxito');

  const couponId = data?.id;
  const prize = data?.prize;
  const companyName = data?.card.cardTemplate.company.name;
  const usedAt = data?.usedAt;
  const clientName = `${data?.card.client.name}
   ${data?.card.client.familyName}`;

  return (
    <Row style={{ width: '90%', margin: 'auto' }}>
      {!loading
        ? (
          <Col style={{ width: '100%' }} span={24} md={12}>
            <h1>{clientName}</h1>
            {prize && companyName && (
            <CouponTicket
              prize={prize}
              companyName={companyName}
              usedAt={usedAt}
              clickable={false}
            />
            )}
            {!usedAt && couponId ? (
              <Button
                style={{ margin: '1vh' }}
                type="primary"
                onClick={() => markCouponAsUsed({ variables: { id: couponId } })}
              >
                <h5>Marcar como usado</h5>
              </Button>
            )
              : (
                <h4>
                  Este cupón fue usado el
                  {' '}
                  {formatDate(new Date(usedAt as string))}
                  {' '}
                  a las
                  {' '}
                  {formatTime(new Date(usedAt as string))}
                </h4>
              )}
          </Col>
        )
        : <LoadingModal visible={loading} />}
    </Row>
  );
};

export default Coupon;
