import React, { createElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from 'components/MainLayout';
import { Route as RouteType } from './routes';
import { isIDTokenValid } from '../auth/storage';

const AuthRoute = (props: RouteType) => {
  const { component: Component, private: privateRoute, path } = props;
  const userIsValid = isIDTokenValid();
  if (userIsValid && path === '/log-in') {
    return <Redirect to="/" />;
  }
  if (!userIsValid && privateRoute) {
    return (
      <Redirect
        to={{
          pathname: '/log-in',
          // eslint-disable-next-line no-restricted-globals
          state: { from: location.pathname },
        }}
      />
    );
  }
  return (
    <Layout>
      <Route path={path} render={() => createElement(Component)} />
    </Layout>
  );
};

export default AuthRoute;
