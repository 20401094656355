const diffInDays = (dateA: Date | string, dateB: Date | string): number => {
  /* Gets (absolute value) difference in calendar days (not cronological as 24h). Uses local time.
  There will be 1 day diff between 16/05/22 at 23:59:59 and 17/05/22 at 00:00:00 */
  const dateAObject = new Date(dateA);
  const dateBObject = new Date(dateB);

  dateAObject.setHours(0);
  dateAObject.setMinutes(0);
  dateAObject.setSeconds(0);
  dateAObject.setMilliseconds(0);
  dateBObject.setHours(0);
  dateBObject.setMinutes(0);
  dateBObject.setSeconds(0);
  dateBObject.setMilliseconds(0);

  const diff = dateAObject.valueOf() - dateBObject.valueOf();
  const daysDiff = Math.ceil(diff / 1000 / 60 / 60 / 24);
  return daysDiff;
};

export default diffInDays;
