import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { columnsTitles } from 'assets/texts/clients';
import { formatDate, daysInTable } from 'assets/texts/utils';
import { TableClient } from './types';

export default [
  {
    title: columnsTitles.fullName,
    dataIndex: 'fullName',
    key: 'fullName',
    defaultSortOrder: 'ascend' as SortOrder,
    sorter: (a: TableClient, b: TableClient) => a.fullName.localeCompare(b.fullName),
    render: (name: string, record: TableClient) => (
      <Tooltip placement="topLeft" title={record.email}>{name}</Tooltip>
    ),
  },
  {
    title: columnsTitles.subscribedAt,
    dataIndex: 'subscribedAt',
    key: 'subscribedAt',
    render: formatDate,
    sorter: (a: TableClient, b: TableClient) => a.subscribedAt.valueOf() - b.subscribedAt.valueOf(),
  },
  {
    title: columnsTitles.totalChecks,
    dataIndex: 'totalChecks',
    key: 'totalChecks',
    sorter: (a: TableClient, b: TableClient) => a.totalChecks - b.totalChecks,
  },
  {
    title:
  <>
    {columnsTitles.recentVisitsAveragePeriod.title}
    <Tooltip title={columnsTitles.recentVisitsAveragePeriod.tooltip}>
      <InfoCircleOutlined />
    </Tooltip>
  </>,
    dataIndex: 'recentVisitsAveragePeriod',
    key: 'recentVisitsAveragePeriod',
    render: daysInTable,
    sorter: (
      a: TableClient,
      b: TableClient,
    ) => (a.recentVisitsAveragePeriod || 0) - (b.recentVisitsAveragePeriod || 0),
    showSorterTooltip: false,
  },
  {
    title: columnsTitles.lastVisitDistance,
    dataIndex: 'lastVisitDistance',
    key: 'lastVisitDistance',
    render: daysInTable,
    sorter: (a: TableClient, b: TableClient) => a.lastVisitDistance - b.lastVisitDistance,
  },
  {
    title: columnsTitles.couponsUsed,
    dataIndex: 'couponsUsed',
    key: 'couponsUsed',
    sorter: (a: TableClient, b: TableClient) => a.couponsUsed - b.couponsUsed,
  },
  {
    title: columnsTitles.activeCoupons,
    dataIndex: 'activeCoupons',
    key: 'activeCoupons',
    sorter: (a: TableClient, b: TableClient) => a.activeCoupons - b.activeCoupons,
  },
  {
    title: columnsTitles.currentCardChecks,
    dataIndex: 'currentCardChecks',
    key: 'currentCardChecks',
    render: (visits: number, record: TableClient) => (`${visits}/${record.currentCardRepetitions}`),
    sorter: (a: TableClient, b: TableClient) => a.currentCardChecks - b.currentCardChecks,
  },
];
