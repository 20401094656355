import { gql, useQuery } from '@apollo/client';
import { Coupon, QueryGetCouponArgs } from 'apollo/generated/types';

const GET_COUPON = gql`
  query getCoupon($id: String!) {
    getCoupon(id: $id) {
      id
      prize
      createdAt
      usedAt
      card {
        id
        cardTemplate {
          icon
          company {
            name
          }
        }
        client {
          name
          familyName
        }
      }
    }
  }
`;

const useGetCouponQuery = (id: string) => useQuery<
{ getCoupon: Coupon }, QueryGetCouponArgs
>(GET_COUPON, { variables: { id } });
export default useGetCouponQuery;
