import { gql, useMutation } from '@apollo/client';
import { Check, CreateCheckInput } from 'apollo/types';

const CREATE_CHECK = gql`
  mutation createCheck(
    $comment: String
  ) {
    createCheck(
      input: {
        comment: $comment
      }
    ) {
      id
      cardTemplate {
        company {
          name
        }
      }
    }
  }
`;

const useCreateCheckMutation = () => useMutation<
  { createCheck: Check }, CreateCheckInput
>(CREATE_CHECK);

export default useCreateCheckMutation;
