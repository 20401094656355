/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { storeIDToken } from 'auth/storage';
import userPool from 'auth/userPool';

const logIn = (
  email: string,
  password: string,
  onSuccessFunction?: () => void,
  onLoadingFunction?: () => void,
  onErrorFunction?: (error: Error) => void,
) => {
  if (onLoadingFunction) onLoadingFunction();
  const authData = {
    Username: email,
    Password: password,
  };
  const authDetails = new AuthenticationDetails(authData);
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.authenticateUser(authDetails, {
    onSuccess(result: CognitoUserSession) {
      storeIDToken({
        userId: result.getIdToken().payload.sub as string,
        idToken: result.getIdToken().getJwtToken(),
        authenticated: true,
        username: email,
      });
      if (onSuccessFunction) onSuccessFunction();
    },
    onFailure(error: Error) {
      if (onErrorFunction) onErrorFunction(error);
    },
    // // eslint-disable-next-line @typescript-eslint/no-empty-function
    // newPasswordRequired: ((userAttributes) => {}),
  });
};

export default logIn;
