import { gql, useQuery } from '@apollo/client';
import { Card, QueryGetCardArgs } from 'apollo/generated/types';

const GET_OR_CREATE_CARD = gql`
  query getCard($cardId: String!) {
    getCard(cardId: $cardId) {
      id
      cardTemplate {
        prize
        repetitions
        icon
      }
      checks {
        createdAt
      }
      client {
        dni
        email
        name
        familyName
      }
    }
  }
`;

const useGetCardQuery = (cardId: string) => useQuery<
{ getCard: Card }, QueryGetCardArgs
>(GET_OR_CREATE_CARD, { variables: { cardId } });
export default useGetCardQuery;
