import {
  Row, Col,
} from 'antd';
import useListMyClientsQuery from 'apollo/resolvers/client/listMy';
import useGetMyCompanyQuery from 'apollo/resolvers/company/getMy';
import ClientsTable from 'components/clients/ClientsTable';
import { TableClient } from 'components/clients/ClientsTable/types';
import LoadingModal from 'components/generalUI/LoadingModal';
import React, { FC } from 'react';
import parseClientData from 'components/clients/ClientsTable/parseClientData';
import * as texts from '../../assets/texts/clients';

const Clients: FC = () => {
  const { data, loading: clientsLoading } = useListMyClientsQuery();
  const { data: companyData, loading: companyLoading } = useGetMyCompanyQuery();
  const loading = clientsLoading || companyLoading;

  const companyName = companyData?.getMyCompany.name;
  const parsedClientsData = data?.listMyClients.map(parseClientData).filter((el) => !!el) as
  TableClient[];

  return (
    <Row style={{ width: '90%', margin: 'auto' }}>
      <Col span={24}>
        <h1>{texts.title}</h1>
        {
          loading
            ? <LoadingModal visible />
            : (
              <>
                <h3>{texts.subTitle(companyName, data?.listMyClients.length || 0)}</h3>
                <ClientsTable parsedClientsData={parsedClientsData} />
              </>
            )
        }
      </Col>
    </Row>
  );
};
export default Clients;
