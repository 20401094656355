import Checks from 'components/card/Checks';
import LoadingModal from 'components/generalUI/LoadingModal';
import { FC } from 'react';
import { Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import useGetCardQuery from 'apollo/resolvers/card/get';
import getActiveChecksNumber from 'utils/card/getActiveChecksNumber';
import getCardNumber from 'utils/card/getCardNumber';

interface QueryParams {
  cardId: string;
}

const Card: FC = () => {
  const { cardId } = useParams<QueryParams>();
  const { data, loading } = useGetCardQuery(cardId);
  const card = data?.getCard;

  const repetitions = card?.cardTemplate.repetitions;
  const prize = card?.cardTemplate.prize;
  const clientName = `${card?.client.name} ${card?.client.familyName}`;
  const cardNumber = getCardNumber(card?.checks.length, repetitions);
  const activeChecksNumber = getActiveChecksNumber(card?.checks.length, repetitions);
  const activeChecks = (activeChecksNumber && card?.checks.slice(-activeChecksNumber)) || [];

  return (
    <Row style={{ width: '90%', margin: 'auto', marginBottom: '6vh' }}>
      {repetitions // repetitions used in condition for type safety
        ? (
          <Col style={{ width: '100%' }} span={24} md={12}>
            <h1>{clientName}</h1>
            <h2>
              Su tarjeta #
              {cardNumber}
            </h2>
            <Checks repetitions={repetitions} checks={activeChecks} />
            <h4 style={{ margin: '2vh auto 4vh auto' }}>
              Al juntar
              {` ${repetitions}`}
              {' '}
              obtiene
              {` ${prize}`}
            </h4>
          </Col>
        )
        : <LoadingModal visible={loading} />}
    </Row>
  );
};
export default Card;
