export const capitalizeFirstLetter = (text: string): string => text.charAt(0).toUpperCase()
+ text.slice(1);

export const formatDate = (date: Date): string => `${date.getDate()}/${
  date.getMonth() + 1
}/${date.getFullYear().toString().slice(-2)}`;

export const formatTime = (date: Date): string => `${date.getHours()}:${date.getMinutes()}hrs`;

export const daysInTable = (days: number | null) => {
  // eslint-disable-next-line eqeqeq
  if (!days && days != 0) {
    return 'n/a';
  }
  let parsed = (`${days} día`); if (days !== 1) {
    parsed += 's';
  } return parsed;
};
