export const title = 'Mis Clientes';
export const subTitle = (companyName: string | undefined, clientsTotal: number) => `
¡Hay ${clientsTotal} clientes registrados con Volbi en ${companyName || 'tu negocio'}!`;

export const columnsTitles = {
  fullName: 'Nombre y correo',
  subscribedAt: 'Suscrito/a el',
  totalChecks: 'Visitas registradas',
  lastVisitDistance: 'Última visita hace',
  couponsUsed: 'Cupones canjeados',
  activeCoupons: 'Cupones por canjear',
  currentCardChecks: 'Avance tarjeta actual',
  recentVisitsAveragePeriod: {
    title: 'Visita cada',
    tooltip: 'Se calcula el promedio de tiempo transcurrido entre las visitas de los últimos '
    + '60 días. No se considera el tiempo desde la última visita hasta ahora.',
  },
};
