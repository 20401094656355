import React, { FC } from 'react';
import ReviLogo from 'assets/images/logo.png';
import { Col, Row, Button } from 'antd';
import * as texts from 'assets/texts/navBar';
import styles from './NavBar.module.scss';

const NavBar: FC = () => {
  const path = window.location.pathname;
  return (
    <div>
      <Row justify="space-around" className={styles.Container}>
        <Col xs={{ span: 10 }} span={16}>
          <a href="/">
            <img src={ReviLogo} alt="Volbi Logo" className={styles.Logo} />
          </a>
        </Col>
        <Col xs={{ span: 14 }} span={8}>
          { path.substring(0, 0) !== '/material'
            && (
              <div className={styles.ButtonsColumn}>
                <Button
                  className="primary"
                  href="/material"
                >
                  <h5>{texts.graphicalMaterialButton}</h5>
                </Button>
              </div>
            )}
          { path.substring(0, 12) !== '/give-stamps'
            && (
              <div className={styles.ButtonsColumn}>
                <Button
                  className="dark"
                  href="/give-stamps"
                >
                  <h5>{texts.giveStamps}</h5>
                </Button>
              </div>
            )}
          { path.substring(0, 5) !== '/scan'
            && (
            <div className={styles.ButtonsColumn}>
              <Button
                className="dark"
                href="/scan-coupon"
              >
                <h5>{texts.scanButton}</h5>
              </Button>
            </div>
            )}
        </Col>
      </Row>
    </div>
  );
};

export default NavBar;
